import icons from 'webapp/images/icons'

export const paymentMethodsDetails = {
  LINE: {
    bankDataKey: 'lineData',
    defaultPaymentType: 'LINE',
    editTrans: 'editLinePayAccount',
    icon: icons.LinePayIcon,
    infoKey: 'referenceNo',
    name: 'LINE',
    setupTrans: 'setUpLinePay',
  },
  PAYPAL: {
    bankDataKey: 'paypalWithdrawData',
    defaultPaymentType: 'PAYPAL',
    editTrans: 'editPayPalAccount',
    icon: icons.PayPalIcon,
    infoKey: 'email',
    name: 'PAYPAL',
    setupTrans: 'setUpPaypal',
  },
  TRANSFERWISE: {
    bankDataKey: 'transferWiseData',
    defaultPaymentType: 'TRANSFERWISE',
    editTrans: 'editBankAccount',
    icon: icons.BankIcon,
    infoKey: 'accountNumber',
    name: 'TRANSFERWISE',
    setupTrans: 'setUpBankAccount',
  },
}

export const { LINE, PAYPAL, TRANSFERWISE } = paymentMethodsDetails

const countryPaymentMethods = {
  'en-CA': [ TRANSFERWISE, PAYPAL ],
  'en-GB': [ TRANSFERWISE, PAYPAL ],
  'en-US': [ TRANSFERWISE, PAYPAL ],
  'fr-FR': [ TRANSFERWISE, PAYPAL ],
  'ja-JP': [ TRANSFERWISE, PAYPAL ],
}

export default countryPaymentMethods
